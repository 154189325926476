import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrBefore);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/Chicago");

export function filterPostByPublishedAt(posts, now) {
  return posts.filter((post) => dayjs(post.node.publishedAt).isSameOrBefore(now));
}

export function buildImageObj(source = { asset: {} }) {
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id },
  };

  if (source.crop) imageObj.crop = source.crop;
  if (source.hotspot) imageObj.hotspot = source.hotspot;

  return imageObj;
}
