/** @jsx jsx */
import { jsx, Styled } from "theme-ui";

export default ({ styles = defaultStyles, external = null, ...props }) => (
  <Styled.a
    {...props}
    sx={styles}
    target={`${external && "_blank"}`}
    rel={`${external && "noopener"}`}
  />
);

const defaultStyles = {
  textDecoration: "none",
  transitionDuration: ".3s",
  color: "primary",
  ":visited": {
    color: "primary",
  },
  ":hover": {
    textDecorationLine: "underline",
    transitionDuration: ".3s",
  },
};
