/** @jsx jsx */
import { jsx } from "theme-ui";

export default ({ styles, innerStyles, children }) => {
  return (
    <main sx={styles}>
      <div sx={innerStyles}>{children}</div>
    </main>
  );
};
