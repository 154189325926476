/** @jsx jsx */
import { jsx, IconButton } from "theme-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

export const MenuButton = (props) => {
  return (
    <IconButton
      title="Menu"
      aria-label="Toggle Menu"
      sx={props.styles}
      {...props}
    >
      <FontAwesomeIcon icon={props.isOpen ? faTimes : faBars} size={"2x"} />
    </IconButton>
  );
};
