import React, { Fragment, useState } from "react";
import SEO from "./seo";
import HTMLBody from "./BodyContainer";
import Navbar from "../Navbar";
import BlogContainer from "./BlogContainer";

export default (props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Fragment>
      <SEO />
      <HTMLBody styles={styles.body}>
        <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />
        <BlogContainer
          children={props.children}
          styles={styles.main}
          innerStyles={styles.postContainer}
        />
      </HTMLBody>
    </Fragment>
  );
};

const styles = {
  body: {
    display: "flex",
    flexDirection: "column",
  },
  main: {
    flexGrow: 99999,
    flexBasis: 0,
    marginX: [0, "10%", null, "17%", "15%"],
    paddingX: ["15px", 4],
  },
  postContainer: {
    marginRight: [0, 0, "100px", "150px", "300px"],
  },
};
