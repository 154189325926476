/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "gatsby";
import { MenuButton } from "./MenuButton";
import StyledLink from "../StyledLink";

const menuItems = [
  { text: "About", to: "/about" },
  // { text: "Connect", to: "/connect" },
];

export default ({ isOpen, setIsOpen }) => {
  return (
    <nav
      sx={{
        display: "flex",
        alignItems: "center",
        paddingX: [2, 3, 5],
        paddingTop: 3,
        paddingBottom: 2,
      }}
    >
      <StyledLink
        to="/"
        styles={{
          color: "text",
          fontFamily: "heading",
          fontSize: [3, 4, 5],
          textDecoration: "none",
          ":visited": {
            color: "text",
          },
        }}
        as={Link}
      >
        Knowing Nothing
      </StyledLink>
      <div
        sx={{
          display: [isOpen ? "flex" : "none", "flex"],
          marginLeft: [0, "auto"],
          justifyContent: ["normal", "space-between"],
          alignItems: ["left", "center"],
          position: ["absolute", "static"],
          top: [0, "none"],
          bottom: [0, "none"],
          right: [0, "none"],
          left: [0, "none"],
          bg: ["background", "inherit"],
          width: ["100vw", "inherit"],
          marginTop: ["86px", 0],
          flexDirection: ["column", "row"],
        }}
      >
        {menuItems.map((item) => (
          <StyledLink
            as={Link}
            styles={{
              textDecoration: "none",
              marginX: [2, "10px"],
              marginY: ["20px", "none"],
              transitionDuration: ".3s",
              color: "primary",
              ":visited": {
                color: "primary",
              },
              ":hover": {
                textDecorationLine: "underline",
                transitionDuration: ".3s",
              },
            }}
            to={item.to}
          >
            {item.text}
          </StyledLink>
        ))}
      </div>
      <MenuButton
        styles={{
          marginLeft: "auto",
          display: [null, "none"],
        }}
        iconSize={50}
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      />
    </nav>
  );
};
