import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import { imageUrlFor } from "../../utils/image-url";
import { buildImageObj } from "../../utils/helpers";
import favicon from "../../images/favicon.ico";

function SEO({ description, lang, meta, keywords, title, image }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaDescription =
          description ||
          (data.sanitySiteSettings && data.sanitySiteSettings.description) ||
          "";
        const siteTitle =
          (data.sanitySiteSettings && data.sanitySiteSettings.title) || "";
        const siteKeywords =
          keywords ||
          (data.sanitySiteSettings && data.sanitySiteSettings.keywords) ||
          "";
        const metaImage =
          image && image.asset
            ? imageUrlFor(buildImageObj(image)).width(1200).url()
            : "";

        return (
          <Helmet
            htmlAttributes={{ lang }}
            title={siteTitle}
            titleTemplate={siteTitle}
            meta={[
              {
                name: "description",
                content: metaDescription,
              },
              {
                property: "og:title",
                content: title,
              },
              {
                property: "og:description",
                content: metaDescription,
              },
              {
                property: "og:type",
                content: "website",
              },
              {
                property: "og:image",
                content: metaImage,
              },
            ]
              .concat(
                siteKeywords && siteKeywords.length > 0
                  ? {
                      name: "keywords",
                      content: siteKeywords.join(", "),
                    }
                  : []
              )
              .concat(meta)}
          >
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="../images/apple-touch-icon.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="../images/favicon-32x32.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="../images/favicon-16x16.png"
            />
            <link rel="icon" href={favicon} />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
              href="https://fonts.googleapis.com/css2?family=Quattrocento+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Work+Sans:wght@600&display=swap"
              rel="stylesheet"
            />
          </Helmet>
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: "en",
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    sanitySiteSettings {
      title
      description
      keywords
    }
  }
`;
